<template>
  <div>
    <h2>You're the best chimpmunk ! Happy first Decembre</h2>
    <h6><i>PS: tu as le droit d'ouvrir ton premier chocolat ;)</i></h6>
    <h5>&#9825;</h5>
  </div>
</template>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
